import fetch from 'auth/FetchInterceptor'

const JwtAuthService = {
	_url: '/rest/v1',

	async login (data) {
		const { email : username, password } = data;
		const resp = await fetch({
			url: `${this._url}/login`,
			method: 'post',
			headers: {
				'public-request': 'true',
			},
			data: { username, password }
		});

		return resp.data;
	},

  async logout () {

    const resp = await fetch({
      url: `${this._url}/logout`,
      method: 'post',
      headers: {
        'public-request': 'true',
      },

    });

    return resp.data;
  },

  async signUp (data) {
		const resp = await fetch({
			url: `${this._url}/signup`,
			method: 'post',
			data: ''
		});
		return resp.data;
	},

	async getProfile (data) {

		return fetch({
			url: `${this._url}/get-profile`,
			method: 'get',
			data: data
		})
	},

  async getAllowedUrls (mask) {
    const resp = await fetch({
      url: `${this._url}/get-allowed-urls` + (mask ? `?mask=${mask}` : ''),
      method: 'get',
    });

    return resp?.data?.results ?? [];
  },

}


export default JwtAuthService