import fetch from 'auth/FetchInterceptor'
import generateBoundary from "./utils";

const UserProfileService = {
	_url: '/rest/v1',

  async getNavPermissions() {

    return fetch({
      url: `${this._url}/get-nav-permissions`,
      method: 'get',

    }).then((resp) => {
      return resp.data;
    });
  },


	async getProfile() {

		return fetch({
			url: `${this._url}/get-profile`,
			method: 'get',

		}).then((resp) => {
			return resp.data;
		});
	},

  async saveProfile(data) {

    return fetch({
      url: `${this._url}/save-profile`,
      method: 'patch',
      headers: {
        'Content-Type': `multipart/form-data; boundary=${generateBoundary()}`,
      },
      data,
    }).then((resp) => {
      return resp.data;
    });
  },

  async deleteOwnAvatar() {

    return fetch({
      url: `${this._url}/delete-own-avatar`,
      method: 'patch',
    }).then((resp) => {
      return resp.data;
    });
  },
  async deleteOwnManagerNicknameAvatar() {

    return fetch({
      url: `${this._url}/delete-own-manager-nickname-avatar`,
      method: 'patch',
    }).then((resp) => {
      return resp.data;
    });
  },

  async changePassword(data) {

    return fetch({
      url: `${this._url}/change-password`,
      method: 'post',
      data: data
    }).then((resp) => {
      return resp.data;
    });
  },

}
export default UserProfileService;


