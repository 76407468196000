import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNOUT,
} from '../constants/Auth';
import {
	showAuthMessage,
	signOutSuccess,
} from "../actions/Auth";
import JwtAuthService from 'services/JwtAuthService';



export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      yield call([JwtAuthService, JwtAuthService.logout]);
      localStorage.removeItem(AUTH_TOKEN);
      yield put(signOutSuccess());
    } catch (err) {
      yield put(showAuthMessage(err.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
		fork(signOut),
  ]);
}
